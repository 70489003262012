@import 'theme/colors.scss';

main {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-areas: "nav body";
  height: 100vh;
}

header {
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 10;
  padding: 1.45rem 0.5rem;
  background-color: $black;
  height: 100vh;
  grid-area: nav;
}

#body {
  margin-left: 3rem;
  grid-area: body;
}

.appOffline {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
}

.appOffline p {
  margin-top: 0.2rem;
  font-weight: bold;
}

.appOffline i {
  font-size: 4.5rem;
  margin-bottom: 1rem;
}

/* ========== Media Queries ========== */
@media only screen and (max-width: 1280px) {

}