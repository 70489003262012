@import 'theme/colors.scss';

.totalValue {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  grid-area: chart;
  font-size: 1.35rem;
  font-weight: 700;
  color: $white;
  margin-top: 1.75rem;
}

.totalValue p {
  margin: 0;
}

.totalValue .subText {
  font-weight: bold;
  font-size: 1rem;
  color: $grey;
}

.totalValue i.fab::before {
  position: relative;
  top: 0.05rem;
}

.totalValue button {
  position: relative;
  top: -0.35rem;
  z-index: 1;
}

.totalValue button i::before,
.totalValue button i::after {
  font-size: 1.5rem;
}