@import 'theme/colors.scss';

.card {
  display: grid;
  flex: 1 1 auto;
  gap: 0 0.5rem;
  grid-template-rows: auto 1fr auto;
  grid-template-columns: 6.7rem 1fr;
  grid-template-areas:
    "cardImage cardTitle"
    "cardImage cardPreview"
    "cardFooter cardFooter";

  border-radius: 0.35rem;
  background-color: $darkerGrey2;
  width: 29rem;
  height: 11rem;
}

.cardTitle,
.cardPreview {
  padding: 0 1rem;
}

.cardImage {
  grid-area: cardImage;
}

.cardImage img {
  width: 7.3rem;
  height: 100%;
  border-radius: 0.35rem 0 0;
}

.cardTitle {
  grid-area: cardTitle;
}

.cardTitle a {
  text-decoration: none;
}

.cardTitle h4 {
  color: $white;
  margin: 1rem 0 0.5rem 0;
}

.cardPreview {
  overflow: hidden;
  grid-area: cardPreview;
}

.cardPreview .previewText {
  margin: 0;
  font-size: 0.85rem;
  font-weight: 300;
  height: 100%;
  overflow: hidden;
}

.cardFooter {
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  font-size: 0.85rem;
  font-weight: 300;
  padding: 0.75rem 1rem 1rem;
  border-radius: 0 0 0.35rem 0.35rem;
  background-color: $black;
  color: $lightGrey;
  grid-area: cardFooter;
}

.cardFooter .source {
  width: auto;
}

.cardFooter .source a {
  color: $lightGrey;
  text-decoration: none;
  display: flex;
  align-items: center;
}

.cardFooter .source a:hover {
  color: $white;
}

.cardFooter .source img {
  width: 1.5rem;
  height: 1.5rem;
  margin-right: 0.5rem;
  border-radius: 0.15rem;
  background-color: white;
}

.cardFooter .sourceName {
  font-weight: bold;
  font-size: 0.8rem;
}

.cardFooter .publishDate {
  font-size: 0.75rem;
  font-weight: normal;
  margin: 0;
  color: $lightGrey;
}

/* ========== Media Queries ========== */
@media only screen and (max-width: 560px) {
  .card {
    width: 100%;
  }

  .cardPreview .previewText {
    display: none;
  }

  .cardImage img {
    width: 7.3rem;
  }
}

@media only screen and (max-width: 450px) {
  .card {
    grid-template-columns: 5rem 1fr;
    height: 8.5rem;
  }

  .cardImage img {
    width: 5.5rem;
  }

  .cardTitle h4 {
    font-size: 0.85rem;
  }

  .cardFooter {
    padding: 0.5rem;
  }
}