@import 'theme/colors.scss';

.pagination {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 1rem;
}

.pagination .pages {
  text-align: center;
}

.pagination button {
  width: 2.5rem !important;
  height: 2.5rem !important;
  border-radius: 50%;
  padding: 0;
}

.pagination button i {
  font-size: 1.5rem !important;
}

.pagination .pages button i {
  font-size: 0.8rem !important;
}

/* ========== Media Queries ========== */
@media only screen and (max-width: 414px) {

}