@import 'theme/colors.scss';

#marketcapBody .capDetails {
  grid-area: details;
}

#marketcapBody .capTable {
  grid-area: table;
}

#marketcap {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

#marketcap li {
  display: flex;
  flex: 1 1 auto;
  width: 100%;
}

#marketcap li .marketcapCard {
  cursor: pointer;
}

#marketcap li:first-child {
  border-top-left-radius: 0.35rem;
  border-top-right-radius: 0.35rem;
}

#marketcap li:last-child {
  border-bottom-left-radius: 0.35rem;
  border-bottom-right-radius: 0.35rem;
}

#marketcap li:nth-child(odd) {
  background-color: $darkerGrey2;
}

#marketcap li:nth-child(even) .marketcapCard:hover {
  background-color: rgba(51, 54, 58, 0.45);
}

#marketcap li:nth-child(odd) .marketcapCard:hover {
  background-color: rgba(51, 54, 58, 0.65);
}

#marketcap li:first-child .marketcapCard:hover {
  background-color: $transparent;
  cursor: default;
}

#marketcap li:first-child .marketcapCard {
  border-top-left-radius: 0.35rem;
  border-top-right-radius: 0.35rem;
}

#marketcap li:first-child .marketcapCard .symbol {
  border-top-left-radius: 0.35rem;
}

#marketcap li:last-child .marketcapCard .symbol {
  border-bottom-left-radius: 0.35rem;
}

#marketcap li:last-child .marketcapCard {
  border-bottom-left-radius: 0.35rem;
  border-bottom-right-radius: 0.35rem;
  margin-bottom: 0;
  border-bottom: 0.0625rem solid $darkerGrey2;
}

/* ========== Media Queries ========== */
@media only screen and (max-width: 1440px) {
  #marketcapBody .capDetails {
    display: none;
  }
}