@import 'theme/colors.scss';

.orderCard {
  display: grid;
  flex: 1 1 auto;
  align-items: center;
  gap: 0 1rem;
  grid-template-columns: 2rem repeat(4, 1fr);
  grid-template-areas:
    "type size price fee time";

  width: 100%;
  height: 3.25rem;
  padding: 0 1rem;
  font-size: 0.85rem;
  color: $lightGrey;
  transition: all 0.2s ease;
}

.openOrderCard {
  grid-template-columns: 6rem repeat(6, 1fr);
  grid-template-areas:
    "cancel type size filled price fee status";
}

.openOrderCard .cancel {
  padding: 0 0.25rem;
  text-align: center;
  margin-left: -0.5rem;
  font-size: 1rem;
  color: $slate;
}

.openOrderCard .cancel button {
  font-size: 0.9rem;
  padding: 0.5rem;
  height: auto;
}

.orderCard .type {
  font-weight: bold;
}

.orderCard .icon {
  display: inline-flex;
  align-items: center;
}

.orderCard .time {
  text-align: right;
}

.orderHeader {
  margin: 0 0.5rem;
}

/* ========== Media Queries ========== */
@media only screen and (max-width: 768px) {
  .openOrderCard {
    grid-template-columns: repeat(2, 6rem) repeat(5, 1fr);
  }
}