@import 'theme/colors.scss';

.progressBar,
.progressLength {
  height: 0.43rem;
  border-radius: 0.35rem;
}

.progressBar {
  display: flex;
  align-items: center;
  background-color: $black;
}

.progressLength {
  height: 0.35rem;
  transition: all 0.75s cubic-bezier(.75,-0.5,0,1.75);
}

/* ========== Media Queries ========== */
@media only screen and (max-width: 840px) {
  .walletCard .position .progressBar {
    display: none;
  }
}