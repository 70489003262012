@import 'theme/colors.scss';

.modal {
  display: flex;
  justify-content: center;
  position: fixed;
  z-index: 12;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
}

.modalBG {
  position: absolute;
  z-index: 20;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8);
  transition: background-color 0.3s ease;
}

.modalBG-enter {
  opacity: 0;
}

.modalBG-exit {
  opacity: 0;
}

.modalWin {
  display: grid;
  gap: 0rem;
  grid-template-rows: auto 1fr auto;
  grid-template-areas: "modalHeader" "modalBody" "modalFooter";

  position: relative;
  z-index: 30;
  width: auto;
  height: fit-content;
  max-width: 80%;
  max-height: 80%;
  min-width: 25rem;
  min-height: 10rem;
  box-sizing: border-box;
  padding: 0; // Usually 1rem;
  border-radius: 0;
  background-color: $transparent; // $white
  border-radius: 0.5rem;
  opacity: 0;
  transition: all 0.5s cubic-bezier(.75,-0.5,0,1.75);
}

.modalHeader {
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  grid-area: modalHeader;

  width: 100%;
  height: 100%;
}

.modalTitle {
  font-size: 1.25rem;
  font-weight: bold;
  margin: 0;
  color: $white;
}

.closeModalIcon {
  font-size: 1.5rem;
  cursor: pointer;
}

.modalBody {
  grid-area: modalBody;
  height: fit-content;
}

.modalBody img {
  width: 100%;
  max-width: 45rem;
}

.modalFooter {
  display: flex;
  justify-content: center;
  grid-area: modalFooter;
  font-size: 0.9rem;
}

/* ========== Media Queries ========== */
@media only screen and (max-width: 414px) {
  .modalWin {
    min-width: 20rem;
  }
}

@media only screen and (max-width: 320px) {
  .modalWin {
    min-width: 19rem;
  }
}