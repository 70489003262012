@import 'theme/colors.scss';

#newsBody {
  display: grid;
  gap: 1rem;
  grid-template-rows: 3.25rem 1fr;
  grid-template-columns: 1fr repeat(2, auto);
  grid-template-areas:
    "title list grid"
    "news news news";

  width: 100%;
  height: auto;
  border-radius: 0.35rem;
  padding: 1rem;
}

#newsBody .newsTitle {
  display: flex;
  align-items: center;
  grid-area: title;
}

#newsBody .newsTitle h2 {
  margin-top: -0.15rem;
}

#newsBody .grid button i::before,
#newsBody .list button i::before {
  position: relative;
  top: 0.1rem;
  font-size: 1.35rem;
}

#newsContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 12rem;
  grid-area: news;
}

#news {
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  border-radius: 0.35rem;
  width: 100%;
  height: auto;
}

#news li {
  display: flex;
  margin: 0.5rem;
  flex: 1 1 auto;
}

/* ========== Media Queries ========== */
@media only screen and (max-width: 1039px) {
  .grid,
  .list {
    display: none;
  }
}