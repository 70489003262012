@import 'theme/colors.scss';

.ordersTable {
  margin-top: 1rem;
}

#filledOrders,
#openOrders {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

#filledOrders li,
#openOrders li {
  display: flex;
  flex: 1 1 auto;
  width: 100%;
}

#filledOrders li .orderCard,
#openOrders li .orderCard {
  cursor: pointer;
}

#filledOrders li:first-child,
#openOrders li:first-child {
  border-top-left-radius: 0.35rem;
  border-top-right-radius: 0.35rem;
}

#filledOrders li:last-child,
#openOrders li:last-child {
  border-bottom-left-radius: 0.35rem;
  border-bottom-right-radius: 0.35rem;
}

#filledOrders li:nth-child(odd),
#openOrders li:nth-child(odd) {
  background-color: $darkerGrey2;
}

#filledOrders li:nth-child(even) .orderCard:hover,
#openOrders li:nth-child(even) .orderCard:hover {
  background-color: rgba(51, 54, 58, 0.45);
}

#filledOrders li:nth-child(odd) .orderCard:hover,
#openOrders li:nth-child(odd) .orderCard:hover {
  background-color: rgba(51, 54, 58, 0.65);
}

#filledOrders li:first-child .orderCard:hover,
#openOrders li:first-child .orderCard:hover {
  background-color: $transparent;
  cursor: default;
}

#filledOrders li:first-child .orderCard,
#openOrders li:first-child .orderCard {
  border-top-left-radius: 0.35rem;
  border-top-right-radius: 0.35rem;
}


#filledOrders li:first-child .orderCard .chainType,
#openOrders li:first-child .orderCard .chainType {
  border-top-left-radius: 0.35rem;
}

#filledOrders li:last-child .orderCard .chainType,
#openOrders li:last-child .orderCard .chainType {
  border-bottom-left-radius: 0.35rem;
}

#filledOrders li:last-child .orderCard,
#openOrders li:last-child .orderCard {
  border-bottom-left-radius: 0.35rem;
  border-bottom-right-radius: 0.35rem;
  margin-bottom: 0;
  border-bottom: 0.0625rem solid $darkerGrey2;
}

/* ========== Media Queries ========== */
@media only screen and (max-width: 765px) {
  .ordersTable {
    display: none;
  }
}