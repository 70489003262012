@import 'theme/colors.scss';

.deleteConfirm {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: $darkerGrey2;
  border-radius: 0.35rem;
  padding: 1rem;
  color: $lightGrey;
}

.deleteConfirm .mainText {
  margin-top: 0;
  font-weight: bold;
}

.deleteConfirm h2 {
  margin-bottom: 0.5rem;
}

.deleteConfirm .address {
  text-align: center;
  max-width: 21rem;
  word-break: break-all;
}

.deleteConfirm button {
  width: 100%;
  margin-top: 1rem;
}

.walletInfo {
  margin: 0;
}