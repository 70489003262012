@import 'theme/colors.scss';

.walletForm {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  background-color: $darkerGrey2;
  border-radius: 0.35rem;
  color: $lightGrey;
  padding: 1rem;
}

/* ========== Media Queries ========== */
@media only screen and (max-width: 440px) {
  .walletForm {
    font-size: 0.8rem;
  }
}