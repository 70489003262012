@import './colors.scss';

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'PT Sans';
  font-weight: 700;
  color: $lightGrey;
  margin: 0;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.sectionTitle {
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: auto;
  margin-top: 0.4rem;
}

.draggable {
  color: $darkGrey;
}

.red {
  color: $red;
}

.white {
  color: $white;
}

.green {
  color: $green;
}

.blue {
  color: $blue;
}

.yellow {
  color: $yellow;
}

.grey {
  color: $grey;
}

.lightGrey {
  color: $lightGrey;
}

.lighterGrey {
  color: $lighterGrey;
}

.darkGrey {
  color: $darkGrey;
}

.darkGrey2 {
  color: $darkGrey2;
}

.darkerGrey {
  color: $darkerGrey;
}

.strong,
.red,
.green,
.blue,
.yellow {
  font-weight: 700;
}

.info,
.warning,
.error {
  position: relative;
  top: 0.2rem;
  left: 0.25rem;
  margin-right: 0.75rem;
}

.info::before,
.warning::before,
.error::before {
  font-size: 1.25rem;
}