@import 'theme/colors.scss';

#portfolioBody {
  display: grid;
  gap: 1rem;
  grid-template-rows: repeat(4, auto) 1fr;
  grid-template-columns: auto 1fr auto;
  grid-template-areas:
    "title . pairToggle"
    "chart chart chart"
    "add add add"
    "search search search"
    "table table table";

  width: 100%;
  height: auto;
  border-radius: 0.35rem;
  padding: 1rem;
}

#portfolioBody .portfolioTitle {
  display: flex;
  align-items: center;
  grid-area: title;
}

#portfolioBody .portfolioTitle h2 {
  margin-top: -0.15rem;
}

#portfolioBody .add {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  grid-area: add;
}

#portfolioBody .add p {
  margin: 0;
}

#portfolioBody .add button {
  position: relative;
  top: 0.1rem;
}

#portfolioBody .add button i {
  position: relative;
  top: -0.05rem;
}

#portfolioBody .portfolioFilter {
  display: flex;
  justify-content: center;
  align-items: center;
  grid-area: search;
}

#portfolioBody .portfolioPairToggle {
  display: inline-flex;
  align-items: center;
  grid-area: pairToggle;
}

#portfolioBody .pieChart {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  width: 18rem;
  height: 18rem;
  grid-area: chart;
}

#portfolioBody .walletsTable {
  grid-area: table;
}

/* ========== Media Queries ========== */
@media only screen and (max-width: 380px) {
  .pieChart {
    width: 17rem !important;
    height: 17rem !important;
  }
}

@media only screen and (max-width: 365px) {
  .pieChart {
    width: 15.75rem !important;
    height: 15.75rem !important;
  }
}