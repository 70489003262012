@import 'theme/colors.scss';

.apiMessagesContainer {
  position: fixed;
  right: 0;
  z-index: 31;
  padding: 1rem;
}

.serverMessage {
  display: grid;
  gap: 0 1rem;
  justify-content: start;
  align-items: center;
  grid-template-columns: auto 1fr auto;
  grid-template-areas: "icon message close";

  background-color: $darkerGrey2;
  border-radius: 0.35rem;
  padding: 0.25rem 1rem;
  height: 5rem;
  width: 19rem;
  margin-bottom: 0.5rem;
  font-size: 0.9rem;
  transition: all 0.575s cubic-bezier(.75,-0.5,0,1.75);
  box-shadow: 0 0.1rem 0.2rem $black;
}

.serverMessage i {
  font-size: 1.35rem;
}

.serverMessage .closeIcon:hover {
  cursor: pointer;
  color: $lightGrey;
}

.serverMessage-enter {
  opacity: 0;
  transform: translateX(4.25rem);
}

.serverMessage-exit {
  opacity: 0;
  transform: translateX(4.25rem);
}

/* ========== Media Queries ========== */
@media only screen and (max-width: 665px) {
  .apiMessagesContainer {
    width: 100%;
  }

  .serverMessage {
    width: 100%;
  }

  .serverMessage-enter {
    transform: translateY(-4.25rem);
  }

  .serverMessage-exit {
    transform: translateY(-4.25rem);
  }
}