@import 'theme/colors';

#notFoundBody {
  display: grid;
  gap: 1rem;
  grid-template-rows: 3rem 1fr;
  grid-template-areas:
    "title"
    "main";

  width: 100%;
  height: auto;
  border-radius: 0.35rem;
  padding: 1rem;
}

#notFoundBody .notFoundTitle {
  display: flex;
  align-items: center;
  grid-area: title;
}

#notFoundBody .notFoundTitle h2 {
  margin-top: -0.15rem;
}

#notFoundBody .notFoundMain {
  display: flex;
  justify-content: center;
  font-weight: 700;
  grid-area: main;
}

/* ========== Media Queries ========== */
@media only screen and (max-width: 414px) {

}