@import 'theme/colors.scss';

.toTop {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  bottom: 0;
  right: 0;
  margin: 1.25rem;
  padding: 1rem;
  border-radius: 0.35rem;
  background-color: $black;
  width: 3rem;
  height: 3rem;
  cursor: pointer;
  opacity: 0;
  transition: all 0.75s cubic-bezier(.75,-0.5,0,1.75);
  transform: translateY(4.25rem);
}

.toTop i {
  color: $white;
  font-size: 2.25rem;
}