@import 'theme/colors.scss';

.nav {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: auto;
  transition: all 0.2s ease;
}

.mainNav {
  height: 100%;
  transition: all 0.2s ease;
}

.nav i.navIcon {
  font-size: 1.4rem;
  margin-top: 1.25rem;
  cursor: pointer;
}

.nav-enter,
.mainNav-enter {
  opacity: 0;
  transform: translateX(-1rem);
}

.nav-exit,
.mainNav-exit {
  opacity: 0;
  transform: translateX(-1rem);
}

.nav i.navIcon:hover {
  color: $white !important;
}

/* ========== Media Queries ========== */
@media only screen and (max-width: 1280px) {

}