@import 'theme/colors.scss';

#accountBody .updateAccount .updateFields {
  display: grid;
  gap: 0.5rem;
  grid-template-rows: 1fr;
  grid-template-columns: repeat(3, 1fr);
  grid-template-areas:
    "newEmail newPassword confirmNewPassword";

  margin-top: 1rem;
}

#accountBody .updateAccount {
  border-radius: 0.35rem;
  margin: 0 auto;
  max-width: 90rem;
}

#accountBody .memberDetails {
  max-width: 13rem;
  font-size: 0.9rem;
}

#accountBody .memberDetails h3 span {
  font-weight: normal;
}

#accountBody .reqs {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-bottom: 1rem;
}

#accountBody .submitBtn {
  text-align: center;
  width: 100%;
}

#accountBody .submitBtn button {
  width: 26.5rem;
}

#accountBody .updateAccount .footnote {
  text-align: center;
  font-size: 0.8rem;
  margin-top: 1rem;
}

#accountBody .updateAccount p {
  margin: 0;
}

#accountBody .legalLinks {
  display: flex;
  justify-content: center;
  margin: 1rem 0 0.5rem 0 !important;
}

.updateAccount .trust {
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* ========== Media Queries ========== */
@media only screen and (max-width: 768px) {
  #accountBody .updateAccount .updateFields {
    gap: 0rem;
    grid-template-rows: repeat(3, auto);
    grid-template-columns: 1fr;
    grid-template-areas:
      "newEmail"
      "newPassword"
      "confirmNewPassword";
  }

  #accountBody .submitBtn button {
    width: 100%;
  }
}