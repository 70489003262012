@import 'theme/colors.scss';

#tradingBody {
  display: grid;
  gap: 1rem;
  grid-template-rows: auto 1fr;
  grid-template-columns: auto 1fr repeat(2, auto);
  grid-template-areas:
    "title . pairToggle settings"
    "main main main main";

  width: 100%;
  height: 100%;
  border-radius: 0.35rem;
  padding: 1rem;
}

#tradingBody .tradingTitle {
  display: flex;
  align-items: center;
  grid-area: title;
}

#tradingBody .tradingTitle h2 {
  margin-top: -0.15rem;
}

#tradingBody .pairToggle {
  display: inline-flex;
  align-items: center;
  grid-area: pairToggle;
}

#tradingBody .main {
  display: grid;
  gap: 1rem;
  grid-template-columns: 22rem 1fr;
  grid-template-rows: repeat(2, auto) 1fr; // repeat(3, auto) 1fr;
  grid-template-areas:
    "tradeDetails orders"
    // "analysis orders"
    "tradeActions orders"
    "note orders";

  grid-area: main;
}

#tradeDetails {
  grid-area: tradeDetails;
}

#tradingBody .orders {
  text-align: center;
  grid-area: orders;
  border-radius: 0.35rem;
  min-height: 18rem;
}

#tradingBody .tradeActions {
  grid-area: tradeActions;
}

#tradingBody .tradeActions .balancesGroup {
  display: grid;
  gap: 1rem;
  grid-template-columns: 1fr;
  width: 100%;
}

#tradingBody .tradeActions .actionGroup {
  display: flex;
  align-items: center;
  width: 100%;
}

.tradeActions button {
  font-size: 0.9rem;
}

#tradingBody .tradeActions .walletVal {
  font-size: 1.2rem;
  margin: 0;
}

#tradingBody .analysis {
  display: inline-flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  border-radius: 0.35rem;
  padding: 0.5rem;
  background-color: $darkerGrey2;
  grid-area: analysis;
}

#tradingBody .note {
  margin: 0;
  font-size: 0.85rem;
  grid-area: note;
}

#tradingBody .tradingSettings {
  display: flex;
  justify-content: flex-end;
  position: relative;
  z-index: 11;
  grid-area: settings;
}

#tradingBody .tradingSettings button i::before {
  font-size: 1.35rem;
}

/* ========== Media Queries ========== */
@media only screen and (max-width: 1280px) {
  #tradingBody .main {
    gap: 2rem 1rem;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(3, auto) 1fr; // repeat(4, auto) 1fr;
    grid-template-areas:
      "tradeDetails"
      // "analysis"
      "tradeActions"
      "note"
      "orders";
  }

  #tradingBody .tradeActions .balancesGroup {
    grid-template-columns: 1fr;
  }
}

@media only screen and (max-width: 414px) {
  #tradingBody {
    gap: 0.5rem;
  }
}

@media only screen and (max-width: 374px) {
  #tradingBody {
    gap: 0.3rem;
  }
}