@import 'theme/colors.scss';

.upperBand {
  stroke-width: 0.1rem;
  stroke: $green;
  fill: none;
}

.middleBand {
  stroke-width: 0.0625rem;
  stroke: $green;
  fill: none;
}

.lowerBand {
  stroke-width: 0.1rem;
  stroke: $green;
  fill: none;
}

.bollingerArea {
  stroke-width: none;
  stroke: none;
  fill: $green;
  fill-opacity: 0.15;
}