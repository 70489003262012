@import 'theme/colors.scss';

#marketcapBody {
  display: grid;
  gap: 1rem;
  grid-template-rows: auto 1fr;
  grid-template-columns: 22rem 8.31rem 1fr auto;
  grid-template-areas:
    "title . search toggle"
    "details table table table";

  width: 100%;
  height: auto;
  border-radius: 0.35rem;
  padding: 1rem;
}

#marketcapBody .capTitle {
  display: flex;
  align-items: center;
  grid-area: title;
}

#marketcapBody .capTitle h2 {
  margin-top: -0.15rem;
}

#marketcapBody .capFilter {
  display: flex;
  justify-content: center;
  align-items: center;
  grid-area: search;
}

#marketcapBody .capPairToggle {
  display: inline-flex;
  align-items: center;
  grid-area: toggle;
}

/* ========== Media Queries ========== */
@media only screen and (max-width: 1440px) {
  #marketcapBody {
    grid-template-rows: repeat(2, auto) 1fr;
    grid-template-columns: 1fr auto;
    grid-template-areas:
      "title toggle"
      "search search"
      "table table";
  }

  #marketcapBody .capTitle h2 {
    margin-top: -0.15rem;
  }
}