@import 'theme/colors.scss';

.candleWickBull,
.candleWickBear {
  stroke-width: 0.0625rem;
  fill: none;
}

.candleBodyBull,
.candleBodyBear {
  stroke-width: 0.5rem;
  fill: none;
}

.candleWickBull,
.candleBodyBull {
  stroke: $lighterGrey;
}

.candleWickBear,
.candleBodyBear {
  stroke: $yellow;
}

.volume {
  stroke-width: 0.5rem;
  stroke: $darkerGrey;
  fill: none;
}

.volMALine {
  stroke-width: 0.075rem;
  stroke-opacity: 0.75;
  stroke: $grey;
  fill: none;
}