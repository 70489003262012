@import 'theme/colors.scss';

.candleChartContainer {
  border-radius: 0.35rem;
  // height dynamically set in CandleChart component.
  width: 100%;
}

.candleChartContainer:hover {
  cursor: crosshair;
}

.candleChartContainer div {
  border-radius: 0.35rem;
}

.timeAxis,
.vAxis {
  stroke: none;
  fill: $darkerGrey2;
  font-size: 0.8rem;
}

.vAxis {
  fill: $darkerGrey2;
}

.timeAxis .domain,
.vAxis .domain {
  stroke: none;
}

.timeAxis .tick line,
.vAxis .tick line {
  stroke-width: 0.15rem;
  stroke: $darkerGrey;
}

.timeAxisDark .tick line,
.vAxisDark .tick line {
  stroke: $darkerGrey;
}

.timeAxis .tick text,
.vAxis .tick text {
  fill: $grey;
  stroke-width: 0.025rem;
  stroke: $grey;
}

.timeBG {
  width: 100%;
  height: 1.25rem;
  background-color: $darkerGrey;
}

.volTimeBG {
  width: 100%;
  height: 1.05rem;
  background-color: $darkerGrey;
}

/* ========== Media Queries ========== */
@media only screen and (max-width: 850px) {

}