@import 'theme/colors.scss';

#loginBody {
  display: grid;
  gap: 1rem;
  grid-template-rows: 2 1fr;
  grid-template-areas:
    "title"
    "form";

  width: 100%;
  height: auto;
  border-radius: 0.35rem;
  padding: 1rem;
}

#loginBody .loginTitle {
  display: flex;
  align-items: center;
  grid-area: title;
}

#loginBody .loginTitle h2 {
  margin-top: -0.15rem;
}

#loginBody .loginForm {
  display: flex;
  justify-content: center;
  grid-area: form;
}

/* ========== Media Queries ========== */
@media only screen and (max-width: 414px) {

}