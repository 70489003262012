@import 'theme/colors.scss';

.search {
  width: 100%;
}

.search label {
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.search input {
  width: 100%;
  max-width: 40rem;
  padding: 0 6rem 0 2.75rem;
  margin: 0;
  background-color: transparent;
  border: 0.125rem solid $darkGrey;
}

.search input:focus {
  box-shadow: none;
}

.search input::placeholder {
  color: $grey;
}

.search i {
  position: absolute;
  float: left;
  left: 0.9rem;
  font-size: 1.25rem;
}

.search .btnContainer {
  margin-left: -6.2625rem;
  border-left: 0.125rem solid $darkGrey;
  transition: opacity 0.15s ease;
}

.search button {
  width: 6rem;
  height: 2.7rem;
  margin-top: -0.05rem;
  border-radius: 0 0.25rem 0.25rem 0;
}

.search button i {
  left: 0;
}

/* ========== Media Queries ========== */
@media only screen and (max-width: 365px) {
  .search input {
    padding: 0 5rem 0 2.75rem;
  }

  .search button {
    width: 5rem;
  }

  .search .btnContainer {
    margin-left: -5.1625rem;
  }
}