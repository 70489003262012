@import 'theme/colors.scss';

.volumeBull,
.volumeBear {
  stroke-width: 0.575rem;
  stroke-opacity: 0.2;
  fill: none;
}

.volumeBull {
  stroke: $lighterGrey;
}

.volumeBear {
  stroke: $yellow;
}

.volMALine {
  stroke-width: 0.075rem;
  stroke-opacity: 0.75;
  stroke: $grey;
  fill: none;
}