@import 'theme/colors.scss';

.tabbedHeaders {
  display: inline-flex;
  justify-content: center;
  list-style-type: none;
  padding: 0;
  width: 100%;
}

.tabbedHeaders li {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem;
  min-width: 7rem;
  height: 3rem;
  cursor: pointer;
}

.tabbedHeaders li:first-child {
  border-radius: 0.5rem 0rem 0rem 0.5rem;
}

.tabbedHeaders li:last-child {
  border-radius: 0rem 0.5rem 0.5rem 0rem;
}

.tabbedHeaders li .tabLabel {
  margin-top: -0.17rem;
}

/* ========== Media Queries ========== */
@media only screen and (max-width: 640px) {
  .tabbedHeaders li {
    font-size: 0.95rem;
    min-width: 6.5rem;
  }
}

@media only screen and (max-width: 490px) {
  .tabbedHeaders li {
    font-size: 0.8rem;
    min-width: 5.8rem;
  }

  .tabbedHeaders li .tabLabel {
    margin-top: 0rem;
  }
}