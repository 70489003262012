@import 'theme/colors.scss';

ul.reqs {
  display: inline-flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 1rem;
}

.reqs li {
  display: inline-flex;
  margin: 0 0.5rem 0 0;
}

.reqs li:last-child {
  margin-right: 0;
}

.reqs li i {
  position: relative;
  top: 0.25rem;
  margin-right: 0.5rem;
}