@import './theme/colors.scss';
@import './theme/common.scss';
@import './theme/forms.scss';
@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700,800|PT+Sans:400,700&display=swap');

* {
  box-sizing: border-box;
}

html {
  -webkit-overflow-scrolling: touch;
  scroll-behavior: smooth;
}

body {
  margin: 0;
  padding: 0;
  font: 0.9375rem/1.5 'Open Sans', Helvetica, Arial, 'sans serif';
  font-weight: 400;
  color: $lightGrey;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: $darkerGrey;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

a:link,
a:visited,
a:active {
  color: $slate;
  font-weight: 700;
}

a:hover {
  color: $white;
}

.fa-spin {
  font-size: 2rem;
}

/* ========== Media Queries ========== */
@media only screen and (max-width: 414px) {

}