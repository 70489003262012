@import 'theme/colors.scss';

.maThin {
  stroke-width: 0.125rem;
  stroke: $purple;
  fill: none;
}

.maThick {
  stroke-width: 0.2rem;
  stroke: $pastelPurple;
  fill: none;
}