@import 'theme/colors.scss';

.legalDoc {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  border-radius: 0.35rem;
  color: $lightGrey;
  padding: 1rem;
}

.modal .legalDoc {
  overflow-y: scroll;
  max-height: 75vh;
  background-color: $darkerGrey2;
}

.legalDoc h3 {
  margin-top: 2rem;
}

.legalDoc ul {
  list-style-type: disc;
  margin-left: 2rem;
}

label.checkContainer.legal {
  margin: 0 auto 1rem auto;
}

/* ========== Media Queries ========== */
@media only screen and (max-width: 440px) {
  .legalDoc {
    font-size: 0.8rem;
  }
}