@import 'theme/colors.scss';

#tradeDetails .detailsGroup {
  display: grid;
  align-items: start;
  grid-gap: 0.5rem;
  grid-template-rows: repeat(3, auto);
  grid-template-areas:
    "tradeAsset"
    "tradeOHLC";
}

#tradeDetails .tradeAsset {
  display: flex;
  flex-direction: column;
  font-weight: 700;
  grid-area: tradeAsset;
}

#tradeDetails .tradeOHLC {
  font-size: 0.8rem;
  grid-area: tradeOHLC;
}

#tradeDetails .tradeOHLC .ohlcContainer {
  display: flex;
  flex-direction: column;
}

#tradeDetails .tradeOHLC p {
  margin: 0 0.25rem;
}

#tradeDetails .tradeOHLC ul {
  display: inline-flex;
}

#tradeDetails .tradeOHLC ul li {
  display: inline-flex;
  font-weight: bold;
  color: $lightGrey;
}

#tradeDetails .tradeOHLC ul li p:first-child {
  margin-left: 0;
}

#tradeDetails .tradeOHLC ul li:first-child p:last-child {
  margin-right: 0.5rem;
}

#tradeDetails .tradeOHLC ul li p:last-child {
  margin-right: 0;
}

#tradeDetails .tradeOHLC span {
  color: $grey;
}

#tradeDetails .tradePrice i {
  position: relative;
  top: 0.65rem;
  vertical-align: super;
}

#tradeDetails .tradePrice {
  margin-top: -0.35rem;
  font-size: 1.75rem;
  font-weight: bold;
  grid-area: tradePrice;
  // "color" dynamically set in ChartDetails.js
}

#tradeDetails .additionalOptions {
  display: grid;
  gap: 0.5rem;
  grid-template-columns: 1fr 4.5rem;
  grid-template-areas: "assetSelect timeframeSelect";

  height: 3rem;
  text-align: right;
}

#tradeDetails .additionalOptions select {
  margin: 0;
}

#tradeDetails .additionalOptions .assetSelect {
  grid-area: assetSelect;
}

#tradeDetails .additionalOptions .timeframeSelect {
  grid-area: timeframeSelect;
}

#tradeDetails .tradePrice .loader {
  display: inline;
  position: relative;
  top: 0.2rem;
  margin: 0;
}

#tradeDetails .tradePrice .loader i {
  font-size: 1.05rem;
}

#tradeDetails .tradePrice .loader .text {
  margin: 0;
}

#tradeDetails .tradePrice .delta {
  position: relative;
  top: -0.1rem;
  font-size: 0.9rem;
}

/* ========== Media Queries ========== */
@media only screen and (max-width: 1266px) {
  #tradeDetails .tradeOHLC {
    justify-content: start;
    margin-top: -0.35rem;
  }

  #tradeDetails .search input {
    max-width: none;
  }

  #tradeDetails .additionalOptions .sourceSelect {
    max-width: none;
  }
}

@media only screen and (max-width: 590px) {
  // #tradeDetails .tradeAsset {
  //   justify-content: center;
  //   text-align: center;
  // }

  // #tradeDetails .tradeOHLC ul {
  //   justify-content: center;
  // }

  // #tradeDetails .tradeOHLC {
  //   text-align: center;
  // }
}

@media only screen and (max-width: 450px) {
  #tradeDetails .tradeOHLC ul {
    flex-direction: column;
    width: 100%;
  }

  // #tradeDetails .tradeOHLC ul li {
  //   margin: 0;
  //   justify-content: center;
  // }

  // #tradeDetails .tradeOHLC ul li:first-child p:last-child {
  //   margin-right: 0;
  // }
}

@media only screen and (max-width: 414px) {
  #tradeDetails .tradeAsset {
    flex-direction: column;
  }

  #tradeDetails .tradePrice i {
    top: 0.7rem;
  }

  #tradeDetails .tradePrice .delta {
    top: -0.1rem;
  }
}