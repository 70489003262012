@import 'theme/colors.scss';

#portfolioBody .walletsTable {
  grid-area: table;
}

#wallets {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

#wallets li {
  display: flex;
  flex: 1 1 auto;
  width: 100%;
}

#wallets li .walletCard {
  cursor: pointer;
}

#wallets li:last-child {
  border-bottom-left-radius: 0.35rem;
  border-bottom-right-radius: 0.35rem;
}

#wallets li:nth-child(even) {
  background-color: $darkerGrey2;
}

#wallets li:nth-child(odd) {
  background-color: $darkerGrey;
}

#wallets li:nth-child(odd) .walletCard:hover {
  background-color: rgba(51, 54, 58, 0.45);
}

#wallets li:nth-child(even) .walletCard:hover {
  background-color: rgba(51, 54, 58, 0.65);
}

#wallets li:last-child .walletCard .chainType {
  border-bottom-left-radius: 0.35rem;
}

#wallets li:last-child .walletCard {
  border-bottom-left-radius: 0.35rem;
  border-bottom-right-radius: 0.35rem;
  margin-bottom: 0;
  border-bottom: 0.0625rem solid $darkerGrey2;
}

/* ========== Media Queries ========== */
@media only screen and (max-width: 1440px) {

}