@import 'theme/colors.scss';

.marketcapCard {
  display: grid;
  flex: 1 1 auto;
  align-items: center;
  gap: 0 0.5rem;
  grid-template-columns: 1rem repeat(2, 1.5rem) 14rem repeat(5, 1fr) 5.5rem;
  grid-template-areas:
    "symbol rank favorite name marketcap dominance volume supply price pctChange";

  width: 100%;
  height: 3.25rem;
  padding: 0.5rem 1rem;
  font-size: 0.85rem;
  color: $lightGrey;
  transition: all 0.2s ease;
}

.marketcapCard .symbol {
  display: flex;
  justify-content: center;
  height: 3.25rem;
  margin: -1rem 0 -1rem 0;
  position: relative;
  left: -1rem;
  background-color: $black;
  width: 1.5rem;
}

.marketcapCard .symbol .name {
  text-align: center;
  color: $lightGrey;
  transform: rotate(270deg);
}

.marketcapCard .name {
  display: inline-flex;
  align-items: center;
}

.marketcapCard #assetName img {
  width: auto;
  height: 1.5rem;
  margin-right: 0.75rem;
  border-radius: 50%;
  background-color: $white;
}

.marketcapCard .supply .name {
  margin-right: 0.5rem;
}

.marketcapCard .pctChange {
  text-align: right;
}

/* ========== Media Queries ========== */
@media only screen and (max-width: 1280px) {
  .marketcapCard {
    grid-template-columns: 1rem repeat(2, 1.5rem) 14rem repeat(4, 1fr) 5.5rem;
    grid-template-areas:
      "symbol rank favorite name marketcap dominance volume price pctChange";
  }

  .marketcapCard .supply {
    display: none;
  }
}

@media only screen and (max-width: 1024px) {
  .marketcapCard {
    grid-template-columns: 1rem repeat(2, 1.5rem) 14rem repeat(3, 1fr) 5.5rem;
    grid-template-areas:
      "symbol rank favorite name marketcap dominance price pctChange";
  }

  .marketcapCard .volume {
    display: none;
  }
}

@media only screen and (max-width: 840px) {
  .marketcapCard {
    grid-template-columns: 1rem repeat(2, 1.5rem) 3rem repeat(3, 1fr) 5.5rem;
    grid-template-areas:
      "symbol rank favorite name marketcap dominance price pctChange";
  }

  .marketcapCard #assetName .fullName {
    display: none;
  }
}

@media only screen and (max-width: 640px) {
  .marketcapCard {
    grid-template-columns: 1rem repeat(2, 1.5rem) 3rem repeat(2, 1fr) 5.5rem;
    grid-template-areas:
      "symbol rank favorite name marketcap price pctChange";
  }

  .marketcapCard .dominance {
    display: none;
  }
}

@media only screen and (max-width: 520px) {
  .marketcapCard {
    grid-template-columns: 1rem repeat(2, 1.5rem) 3rem 1fr 5.5rem;
    grid-template-areas:
      "symbol rank favorite name price pctChange";
  }

  .marketcapCard .marketcap {
    display: none;
  }
}

@media only screen and (max-width: 450px) {
  .marketcapCard {
    grid-template-columns: 1rem repeat(2, 1.5rem) 1fr 5.5rem;
    grid-template-areas:
      "symbol rank favorite price pctChange";
  }

  .marketcapCard #assetName {
    display: none;
  }
}

@media only screen and (max-width: 414px) {
  .marketcapCard {
    gap: 0.1rem;
  }
}

@media only screen and (max-width: 359px) {
  .marketcapCard {
    grid-template-columns: 1rem repeat(2, 1.5rem) 1fr 5rem;
  }

  .marketcapCard .pctChange,
  .marketcapCard .price {
    font-size: 0.8rem;
  }
}