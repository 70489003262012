@import 'theme/colors.scss';

.botIndicatorContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.botIndicatorContainer p {
  font-size: 0.8rem;
  margin: 0;
}

.botIndicator {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  transition: all 0.2s ease;
}

.botIndicator i::before {
  font-size: 1.5rem;
}

/* ========== Media Queries ========== */
@media only screen and (max-width: 414px) {

}