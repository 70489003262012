@import 'theme/colors.scss';

.EMARibbonLineMain {
  stroke-width: 0.125rem;
  stroke: $pastelCyan;
  fill: none;
}

.EMARibbonLine1 {
  stroke-width: 0.125rem;
  stroke: #969abe;
  fill: none;
}

.EMARibbonLine2 {
  stroke-width: 0.125rem;
  stroke: #969abe;
  fill: none;
}

.EMARibbonLine3 {
  stroke-width: 0.125rem;
  stroke: #7082bd;
  fill: none;
}

.EMARibbonLine4 {
  stroke-width: 0.125rem;
  stroke: #5e6bb4;
  fill: none;
}

.EMARibbonLine5 {
  stroke-width: 0.125rem;
  stroke: #5157a8;
  fill: none;
}

.EMARibbonLine6 {
  stroke-width: 0.075rem;
  stroke: #4254b9;
  fill: none;
}

.EMARibbonLine7 {
  stroke-width: 0.075rem;
  stroke: #6f4dce;
  fill: none;
}

.EMARibbonLine8 {
  stroke-width: 0.075rem;
  stroke: #7352cf;
  fill: none;
}