@import 'theme/colors.scss';

.socketIndicator {
  text-align: center;
  transition: all 0.2s ease;
}

.socketIndicator-enter {
  opacity: 0;
  transform: translateX(-1rem);
}

.socketIndicator-exit {
  opacity: 0;
  transform: translateX(-1rem);
}

/* ========== Media Queries ========== */
@media only screen and (max-width: 1280px) {

}