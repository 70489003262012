@import 'theme/colors.scss';

.loadingApp {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  height: 100vh;
}

.loadingApp p {
  margin: 0 0 0.5rem 0;
}

.loadingApp .brand {
  font-size: 2rem;
  font-weight: 700;
}

.loadingApp sup {
  font-size: 0.9rem;
  font-weight: 300;
}

/* ========== Media Queries ========== */
@media only screen and (max-width: 1280px) {
  .loadingApp p {
    margin: 0 0 0.35rem 0;
  }

  .loadingApp .brand {
    font-size: 1.75rem;
  }

  .loadingApp sup {
    font-size: 0.85rem;
  }
}

@media only screen and (max-width: 640px) {
  .loadingApp p {
    margin: 0 0 0.25rem 0;
  }

  .loadingApp .brand {
    font-size: 1.5rem;
  }

  .loadingApp sup {
    font-size: 0.8rem;
  }

  .processText {
    font-size: 0.8rem;
  }
}