@import 'theme/colors.scss';

.stochMainLine {
  stroke-width: 0.2rem;
  stroke: $pastelGreen;
  fill: none;
}

.stochSecLine {
  stroke-width: 0.125rem;
  stroke: $green;
  fill: none;
}

.line-dashed {
  stroke-dasharray: 0.75rem 0.15rem;
  stroke-width: 0.2rem;
  stroke: $darkerGrey2;
}

.rsiArea {
  fill: $darkGrey2;
}

#stochSlowContainer {
  color: $pastelGreen;
  font-weight: bold;
}

#stochSignalContainer {
  color: $green;
  font-weight: normal;
}

#stochSlowContainer,
#stochSignalContainer {
  margin-left: 0.35rem;
}

/* ========== Media Queries ========== */
@media only screen and (max-width: 850px) {

}