@import "theme/colors.scss";

#Brand {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: $lightGrey;
  width: 100%;
}

.brandContainer {
  display: flex;
  align-items: center;
  width: 100%;
}

.brandContainer a,
.brandContainer a:visited,
.brandContainer a:active {
  text-decoration: none;
  width: 100%;
}

#Brand svg {
  height: 2.1rem;
  width: auto;
  fill: $lightGrey;
}

#Brand h1 {
  position: relative;
  top: -0.15rem;
  margin: 0.2rem 0 0 0;
  font-size: 1.85rem;
  color: $lightGrey;
}

#tm {
  position: relative;
  top: -0.35rem;
  font-size: 0.7rem;
}

/* ========== Media Queries ========== */
@media only screen and (max-width: 414px) {

}