@import 'theme/colors';

.loader {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  margin-top: 2rem;
}

.loader .text {
  font-weight: 600;
}

/* ========== Media Queries ========== */
@media only screen and (max-width: 414px) {

}