@import 'theme/colors.scss';

.authForm {
  display: flex;
  flex-direction: column;
  text-align: center;
  margin-top: 1.35rem;
}

#authContainer {
  display: flex;
  flex-direction: column;
  width: 27rem;
}

#authContainer p {
  text-align: center;
}

/* ========== Media Queries ========== */
@media only screen and (max-width: 414px) {
  #authContainer {
    width: 100%;
  }
}