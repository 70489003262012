$white: white;
$black: #0b0c0d;
$offWhite: #f5f5f5;
$darkerGrey: #222425;
$darkerGrey2: #1c1e1f;
$darkGrey2: #34363a;
$darkGrey: #434547;
$grey: #6d747e;
$lightGrey: #b6bec9;
$lighterGrey: #d8dee7;
$violet: #9d4eb9;
$purple: #b880cc;
$magenta: #da3f92;
$pink: #d687a5;
$pastelPurple: #ae9ad8;
$skyBlue: #1d8edf;
$blue: #4aa7e9;
$pastelBlue: #7fBfe4;
$cyan: #00a8b8; // #6dbfc2;
$pastelCyan: #7fd8e4;
$green: #50b990;
$pastelGreen: #7aD5b1;
$darkYellow: #473110;
$yellow: #f7a72e;
$pastelYellow: #df9c38;
$orange: #f3814c;
$red: #c54e4e;
$slate: #7b8a92;
$darkerSlate: #2c3033;
$brown: #927d7b;
$transparent: transparent;

:export {
  white: $white;
  black: $black;
  offWhite: $offWhite;
  darkerGrey: $darkerGrey;
  darkerGrey2: $darkerGrey2;
  darkGrey: $darkGrey;
  darkGrey2: $darkGrey2;
  grey: $grey;
  lightGrey: $lightGrey;
  lighterGrey: $lighterGrey;
  violet: $violet;
  purple: $purple;
  magenta: $magenta;
  pink: $pink;
  pastelPurple: $pastelPurple;
  skyBlue: $skyBlue;
  blue: $blue;
  pastelBlue: $pastelBlue;
  cyan: $cyan;
  pastelCyan: $pastelCyan;
  green: $green;
  pastelGreen: $pastelGreen;
  darkYellow: $darkYellow;
  yellow: $yellow;
  pastelYellow: $pastelYellow;
  orange: $orange;
  red: $red;
  slate: $slate;
  darkerSlate: $darkerSlate;
  brown: $brown;
  transparent: $transparent;
}