@import 'theme/colors.scss';

#chartsBody {
  display: grid;
  gap: 1rem;
  grid-template-rows: repeat(2, auto);
  grid-template-columns: auto 1fr repeat(2, auto);
  grid-template-areas:
    "title . pairToggle settings"
    "chart chart chart chart";

  width: 100%;
  height: auto;
  border-radius: 0.35rem;
  padding: 1rem;
}

#chartsBody .chartsTitle {
  display: flex;
  align-items: center;
  grid-area: title;
}

#chartsBody .chartsTitle h2 {
  margin-top: -0.15rem;
}

#chartsBody .chartsPairToggle,
#chartsBody .chartsSettings {
  display: inline-flex;
  align-items: center;
}

#chartsBody .chartsPairToggle {
  grid-area: pairToggle;
}

#chartsBody .chartsSettings {
  position: relative;
  z-index: 11;
  grid-area: settings;
}

#chartsBody .chartsSettings button i::before {
  font-size: 1.35rem;
}

#chartsBody .chart {
  display: grid;
  grid-gap: 0.5rem;
  grid-template-rows: repeat(3, auto);
  grid-template-columns: 1fr;
  grid-template-areas:
    "chartDetails"
    "candleChart"
    "subCharts";

  margin-bottom: 1rem;
  grid-area: chart;
}

#candleChart {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  grid-area: candleChart;
}

#candleChart .errorHandling {
  text-align: center;
}

#chartsBody .subCharts {
  min-height: 18rem;
  grid-area: subCharts;
  margin-top: 1.5rem;
}

/* ========== Media Queries ========== */
@media only screen and (max-width: 413px) {
  #chartsBody {
    gap: 0.5rem;
  }
}

@media only screen and (max-width: 374px) {
  #chartsBody {
    gap: 0.3rem;
  }
}