@import 'theme/colors.scss';

.toggle {
  position: relative;
  display: inline-block;
  width: 2.5rem;
  min-width: 2.5rem;
  height: 1.25rem;
}

.toggle input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: $darkGrey;
  transition: background-color 0.1s linear;
}

.slider:before {
  position: absolute;
  content: "";
  height: 1.125rem;
  width: 1.125rem;
  top: 0.0625rem;
  left: 0.0625rem;
  bottom: 0.25rem;
  background-color: $lightGrey;
  transition: transform 0.1s linear;
}

input:checked + .slider:before {
  background-color: $white;
}

input:checked + .slider {
  background-color: $skyBlue;
}

input:focus + .slider {
  box-shadow: 0 0 0.0625rem $white;
}

input:checked + .slider:before {
  -webkit-transform: translateX(1.25rem);
  -ms-transform: translateX(1.25rem);
  transform: translateX(1.25rem);
}

input:disabled + .slider:before {
  background-color: $darkerGrey;
}

input:disabled + .slider {
  cursor: not-allowed;
}

.slider.round {
  border-radius: 2.125rem;
}

.slider.round:before {
  border-radius: 50%;
}
