@import 'theme/colors.scss';

.settingsContainer {
  display: none;
  position: absolute;
  z-index: 10;
  top: 1rem;
  right: 0.5rem;
  padding: 0.5rem 2.5rem 1rem 1rem;
  height: auto;
  max-height: calc(100% - 2rem);
  width: 20rem;
  background-color: rgba(16, 18, 20, 0.65);
  backdrop-filter: blur(0.5rem);
  border-radius: 0.35rem;
  box-shadow: 0 0.1rem 0.25rem $black;
  overflow-y: scroll;
  overflow: -moz-scrollbars-none;
  opacity: 0;
  transition: all 0.575s cubic-bezier(.75,-0.5,0,1.75);
}

.mobileSettingsContainer {
  overflow-y: scroll;
  height: auto;
  max-height: 80vh;
  padding: 1rem;
  border-radius: 0.35rem;
  background-color: $darkerGrey2;
}

.settingsContainer::-webkit-scrollbar {
  width: 0 !important;
}

.settingsContainer select {
  margin-bottom: 0;
}

.settingsContainer input,
.mobileSettingsContainer input {
  margin-bottom: 0.5rem;
}

.settingsContainer h2,
.mobileSettingsContainer h2 {
  margin: 0 0 1rem 0;
}

.settingsContainer h4,
.mobileSettingsContainer h4 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin: 1rem 0 0.5rem 0;
}

.settingsContainer h4 button,
.mobileSettingsContainer h4 button {
  font-size: 0.9rem;
  padding: 0.5rem;
  height: auto;
}

.settingsContainer .labelTitle,
.mobileSettingsContainer .labelTitle {
  margin: 0 0 0.5rem 0;
  color: $grey;
  font-size: 0.8rem;
}

.settingGroup {
  display: inline-flex;
  flex: 1 1 auto;
  margin-top: 0.5rem;
}