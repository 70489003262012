@import 'theme/colors.scss';

.macdLine {
  stroke-width: 0.2rem;
  stroke: $pastelBlue;
  fill: none;
}

.signalLine {
  stroke-width: 0.125rem;
  stroke: $blue;
  fill: none;
}

.histogram {
  stroke-width: 0.5rem;
  stroke-opacity: 0.25;
  fill: none;
}

.histogramBull {
  stroke: $lighterGrey;
}

.histogramBear {
  stroke: $yellow;
}

/* ========== Media Queries ========== */
@media only screen and (max-width: 850px) {

}