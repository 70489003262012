@import 'theme/colors.scss';

.rsiLine {
  stroke-width: 0.2rem;
  stroke: $pastelGreen;
  fill: none;
}

.line-dashed {
  stroke-dasharray: 0.75rem 0.15rem;
  stroke-width: 0.2rem;
  stroke: $darkerGrey2;
}

.rsiArea {
  fill: $darkGrey2;
}

/* ========== Media Queries ========== */
@media only screen and (max-width: 850px) {

}