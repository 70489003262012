@import 'theme/colors.scss';

.toggleOptions,
.emaGroup,
.maGroup,
.ichimokuGroup,
.bBandsGroup,
.macdGroup,
.rsiGroup,
.stochRsiGroup {
  display: grid;
  gap: 0.5rem;
}

.toggleOptions {
  gap: 0 0.5rem;
}

.toggleOptions p {
  margin: 0 0 0.5rem 0;
}

.toggleOptions {
  grid-template-columns: repeat(2, 1fr) auto;
}

.emaGroup {
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(3, auto);
}

.maGroup {
  grid-template-columns: repeat(4, 1fr);
}

.ichimokuGroup {
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, auto);
}

.bBandsGroup {
  grid-template-columns: repeat(2, 1fr);
}

.macdGroup {
  grid-template-columns: repeat(3, 1fr);
}

.rsiGroup {
  grid-template-columns: repeat(3, 1fr);
}

.stochRsiGroup {
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(2, 1fr);
}