@import 'theme/colors.scss';

.indicatorToolbar {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.indicatorToolbar button {
  border-radius: 50%;
  width: 3rem;
  height: 3rem;
  margin: 0 0.25rem;
}