@import 'theme/colors.scss';

label.checkContainer {
  display: block;
  position: relative;
  padding-left: 2.1875rem;
  margin-bottom: 0.75rem;
  width: fit-content;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

// Hide default checkbox
label.checkContainer input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.customCheckbox {
  position: absolute;
  top: -0.1rem;
  left: 0;
  height: 1.5625rem;
  width: 1.5625rem;
  border: 0.125rem solid $darkGrey;
  border-radius: 0.25rem;
}

.customCheckbox::after {
  content: "";
  position: absolute;
  display: none;
}

label.checkContainer .customCheckbox::after {
  left: 0.47875rem;
  top: 0.15rem;
  width: 0.25rem;
  height: 0.625rem;
  border: solid $lightGrey;
  border-width: 0 0.1875rem 0.1875rem 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

label.checkContainer input:checked ~ .customCheckbox::after {
  display: block;
}

label.checkContainer .formFeedback {
  margin-left: -2.1875rem;
}