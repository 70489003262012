@import 'theme/colors.scss';

.tradeForms .tabbedHeaders {
  position: relative;
  top: -2.2rem;
  margin-bottom: -1rem;
}

.tradeForms .tabbedHeaders li {
  height: 2.25rem;
  min-width: 5rem;
}