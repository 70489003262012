@import 'theme/colors.scss';

.conversionLine {
  stroke-width: 0.0625rem;
  stroke: $pastelPurple;
  stroke-opacity: 0.75;
  fill: none;
}

.baseLine {
  stroke-width: 0.0625rem;
  stroke: $pastelBlue;
  stroke-opacity: 0.75;
  fill: none;
}

.laggingSpan {
  stroke-width: 0.1rem;
  stroke: $darkGrey2;
  stroke-opacity: 0.75;
  fill: none;
}

.leadingSpanA {
  stroke-width: 0.1rem;
  stroke: $offWhite;
  stroke-opacity: 0.75;
  fill: none;
}

.leadingSpanB {
  stroke-width: 0.1rem;
  stroke: $yellow;
  stroke-opacity: 0.75;
  fill: none;
}

.bullishArea,
.bearishArea {
  stroke-width: none;
  stroke: none;
  fill-opacity: 0.25;
}

.bullishArea {
  fill: $offWhite;
}

.bearishArea {
  fill: $pastelYellow;
}