@import 'theme/colors.scss';

.walletCard {
  display: grid;
  flex: 1 1 auto;
  align-items: center;
  gap: 0 1rem;
  grid-template-columns: repeat(2, 1rem) repeat(5, 1fr) 0.534375rem;
  grid-template-areas:
    "chainType remove walletName balance value position price draggable";

  width: 100%;
  height: 3.25rem;
  padding: 0 1rem;
  font-size: 0.85rem;
  color: $lightGrey;
  transition: all 0.2s ease;
}

.walletCard .chainType {
  display: flex;
  justify-content: center;
  height: 3.25rem;
  margin: -1rem 0 -1rem 0;
  position: relative;
  left: -1rem;
  background-color: $black;
  width: 1.5rem;
}

.walletCard .remove {
  text-align: center;
}

.walletCard .remove button {
  padding: 0;
}

.walletCard .walletName {
  font-weight: bold;
}

.walletCard .address {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  word-break: break-all;
}

.walletCard .address button {
  padding: 0 1rem 0 0;
  height: auto;
}

.walletCard .address button i {
  font-size: 1.25rem !important;
}

.walletCard .chainType .symbol {
  text-align: center;
  color: $lightGrey;
  transform: rotate(270deg);
}

.walletCard .symbol {
  display: inline-flex;
  align-items: center;
}

.walletCard .price {
  font-weight: bold;
  text-align: right;
}

.walletCard .delta {
  font-size: 0.75rem;
  margin-right: 0.5rem;
}

.walletHeader {
  border-top-left-radius: 0.35rem;
  border-top-right-radius: 0.35rem;
  background-color: $darkerGrey2;
}


.walletHeader .chainType {
  border-top-left-radius: 0.35rem;
}

.walletCard .position {
  display: inline-flex;
  align-items: center;
}

.walletCard .position .positionVal {
  min-width: 4rem;
}

/* ========== Media Queries ========== */
@media only screen and (max-width: 1150px) {
  .walletCard {
    grid-template-columns: repeat(2, 1rem) repeat(4, 1fr) 0.534375rem;
    grid-template-areas:
      "chainType remove walletName balance value position draggable";
  }

  .walletCard .price {
    display: none;
  }
}

@media only screen and (max-width: 840px) {
  .walletCard {
    grid-template-columns: repeat(2, 1rem) repeat(3, 1fr) 0.534375rem;
    grid-template-areas:
      "chainType remove walletName balance value draggable";
  }

  .walletCard .position {
    display: none;
  }

  .walletCard .value {
    text-align: right;
  }
}

@media only screen and (max-width: 640px) {
  .walletCard {
    grid-template-columns: 1rem 1.25rem repeat(2, 1fr) 0.534375rem;
    grid-template-areas:
      "chainType remove walletName value draggable";
  }

  .walletCard .balance {
    display: none;
  }
}

@media only screen and (max-width: 414px) {
  .walletCard {
    gap: 0.5rem;
  }

  .walletCard .walletName,
  .walletCard .value {
    font-size: 0.8rem;
  }
}

@media only screen and (max-width: 359px) {
  .walletCard .walletName,
  .walletCard .value {
    font-size: 0.7rem;
  }
}