@import 'theme/colors.scss';

#accountBody {
  display: grid;
  gap: 1rem;
  grid-template-rows: 3.25rem repeat(2, auto);
  grid-template-areas:
    "title"
    "details"
    "delete";

  width: 100%;
  height: auto;
  border-radius: 0.35rem;
  padding: 1rem;
}

#accountBody .accountTitle {
  display: flex;
  align-items: center;
  grid-area: title;
}

#accountBody .accountTitle h2 {
  margin-top: -0.15rem;
}

#accountBody .accountDetails {
  padding: 1rem;
  grid-area: details;
}

#accountBody .delete {
  display: grid;
  gap: 0.5rem;
  grid-template-columns: 1fr auto;
  grid-template-rows: repeat(2, auto);
  grid-template-areas:
    "warning deleteAction"
    "disclaimer deleteAction";

  border: 0.125rem solid $darkerGrey2;
  background-color: rgba(0, 0, 0, 0.125);
  border-radius: 0.35rem;
  padding: 1rem;
  grid-area: delete;
  margin: 0 auto;
  max-width: 90rem;
}

#accountBody .delete .warning {
  top: 0;
  color: $red;
  margin-left: -0.25rem;
  grid-area: warning;
}

#accountBody .delete span {
  font-weight: 700;
}

#accountBody .delete p {
  margin: 0;
}

#accountBody .deleteAction {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-left: 0.125rem solid $darkerGrey2;
  padding-left: 1rem;
  text-align: center;
  grid-area: deleteAction;
}

#accountBody .disclaimer ul {
  display: flex;
  flex-wrap: wrap;
  flex: 1 1 auto;
  list-style-position: inside;
  list-style-type: disc;
  margin-top: 0.5rem;
}

#accountBody .disclaimer li {
  min-width: 13rem;
}

#accountBody .disclaimer {
  grid-area: disclaimer;
}

/* ========== Media Queries ========== */
@media only screen and (max-width: 970px) {

  #accountBody .delete {
    gap: 1rem;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(3, auto);
    grid-template-areas:
      "warning"
      "disclaimer"
      "deleteAction";
  }

  #accountBody .disclaimer {
    text-align: center;
  }

  #accountBody .disclaimer ul {
    justify-content: center;
  }

  #accountBody .disclaimer li {
    text-align: left;
    min-width: 10rem;
  }

  #accountBody .deleteAction {
    border-top: 0.125rem solid $darkerGrey2;
    border-left: none;
    padding: 1rem 0 0 0;
  }
}

@media only screen and (max-width: 768px) {
  #accountBody .warning {
    margin-left: 0;
    text-align: center;
  }
}

@media only screen and (max-width: 414px) {
  #accountBody .warning {
    font-size: 1rem;
  }
}