@import 'theme/colors.scss';

.deleteAccountConfirm {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: $darkerGrey2;
  border-radius: 0.35rem;
  color: $lightGrey;
  padding: 1rem;
}

.deleteAccountConfirm p {
  margin-bottom: 1rem;
}

.deleteAccountConfirm button {
  width: 100%;
}

/* ========== Media Queries ========== */
@media only screen and (max-width: 440px) {
  .deleteAccountConfirm {
    font-size: 0.8rem;
  }
}