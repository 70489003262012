@import 'theme/colors.scss';

.assetDetails {
  display: grid;
  gap: 1rem;
  grid-template-rows: repeat(2, auto) 1fr;
  grid-template-areas:
    "image"
    "asset"
    "info";

  width: 100%;
  height: auto;
  padding-top: 1rem;
  background-color: $darkerGrey2;
  border-radius: 0.35rem;
  color: $lightGrey;
}

.assetDetails .assetImage {
  display: flex;
  justify-content: center;
  grid-area: image;
  margin-top: -2.3rem;
}

.assetDetails .img {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 8.5rem;
  height: 8.5rem;
  padding: 0.5rem;
  border-radius: 50%;
  background-color: $white;
}

.assetDetails img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.assetDetails p {
  margin: 0;
}

.assetDetails .mainDetails {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: auto;
  border-radius: 0.35rem;
  grid-area: asset;
  padding: 0 1rem;
}

.assetDetails .mainDetails h1 {
  font-size: 2.55rem;
  text-align: center;
  line-height: 3rem;
}

.assetDetails .mainDetails h2 {
  display: inline-flex;
  align-items: center;
}

.assetDetails .mainDetails .symbol {
  font-size: 1.75rem;
}

.assetDetails .detailsDelta {
  font-size: 1rem;
}

.assetDetails .generalDetails {
  grid-area: info;
  width: 100%;
  padding: 1rem;
  border-radius: 0 0 0.35rem 0.35rem;
  background-color: $black;
  color: $lightGrey;
};

.assetDetails .watchContainer {
  width: 100%;
  height: auto;
  margin-top: 1rem;
}

.assetDetails .watchContainer button {
  width: 100%;
}

.assetDetails .watchContainer button i {
  position: relative;
  top: -0.15rem;
}

.modal .assetDetails {
  border: none;
  background-color: $darkerGrey;
}

.modal .assetDetails .generalDetails {
  background-color: $darkerGrey2;
}

/* ========== Media Queries ========== */
@media only screen and (max-width: 440px) {
  .assetDetails {
    font-size: 0.8rem;
  }
}