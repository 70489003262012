@import 'theme/colors.scss';

#chartDetails {
  display: grid;
  align-items: center;
  grid-gap: 0.5rem;
  grid-template-rows: repeat(3, auto);
  grid-template-columns: 1fr 40rem 1fr;
  grid-template-areas:
    "chartAsset chartAssetChange additionalOptions"
    "chartOHLC chartOHLC chartTimeLeft";
}

#chartDetails .chartAsset {
  display: flex;
  flex-direction: column;
  font-weight: 700;
  grid-area: chartAsset;
}

#chartDetails .chartAssetChange {
  height: 3rem;
  grid-area: chartAssetChange;
}

#chartDetails .chartOHLC {
  font-size: 0.8rem;
  grid-area: chartOHLC;
}

#chartDetails .chartOHLC .ohlcContainer {
  display: flex;
  flex-direction: column;
}

#chartDetails .chartOHLC p {
  margin: 0 0.25rem;
}

#chartDetails .chartOHLC ul {
  display: inline-flex;
}

#chartDetails .chartOHLC ul li {
  display: inline-flex;
  font-weight: bold;
  color: $lightGrey;
}

#chartDetails .chartOHLC ul li p:first-child {
  margin-left: 0;
}

#chartDetails .chartOHLC ul li:first-child p:last-child {
  margin-right: 0.5rem;
}

#chartDetails .chartOHLC ul li p:last-child {
  margin-right: 0;
}

#chartDetails .chartOHLC span {
  color: $grey;
}

#chartDetails .chartPrice i {
  position: relative;
  top: 0.65rem;
  vertical-align: super;
}

#chartDetails .chartPrice {
  margin-top: -0.35rem;
  font-size: 1.75rem;
  font-weight: bold;
  grid-area: chartPrice;
  // "color" dynamically set in ChartDetails.js
}

#chartDetails .chartPrice .loader {
  display: inline;
  position: relative;
  top: 0.2rem;
  margin: 0;
}

#chartDetails .chartPrice .loader i {
  font-size: 1.05rem;
}

#chartDetails .chartPrice .loader .text {
  margin: 0;
}

#chartDetails .chartPrice .delta {
  position: relative;
  top: -0.1rem;
  font-size: 0.9rem;
}

#chartDetails .additionalOptions {
  display: grid;
  justify-content: right;
  grid-gap: 0.5rem;
  grid-template-columns: 12rem 4.5rem;
  grid-template-areas: "sourceSelect timeframeSelect";

  height: 3rem;
  text-align: right;
  grid-area: additionalOptions;
}

#chartDetails .additionalOptions select {
  margin: 0;
}

#chartDetails .additionalOptions .assetSelect {
  grid-area: assetSelect;
}

#chartDetails .additionalOptions .timeframeSelect {
  grid-area: timeframeSelect;
}

#chartDetails .additionalOptions .sourceSelect {
  max-width: 12rem;
  grid-area: sourceSelect;
}

#chartDetails .chartTimeLeft {
  text-align: right;
  font-weight: bold;
  color: $grey;
  grid-area: chartTimeLeft;
}

/* ========== Media Queries ========== */
@media only screen and (max-width: 1266px) {
  #chartDetails {
    grid-template-rows: repeat(3, auto);
    grid-template-columns: 1fr 17.1rem;
    grid-template-areas:
      "chartAssetChange additionalOptions"
      "chartAsset chartTimeLeft"
      "chartOHLC chartOHLC";
  }

  #chartDetails .chartOHLC {
    justify-content: start;
    margin-top: -0.35rem;
  }

  // #chartDetails .chartAsset {
  //   flex-direction: row;
  //   align-items: center;
  // }

  #chartDetails .search input {
    max-width: none;
  }

  #chartDetails .additionalOptions .sourceSelect {
    max-width: none;
  }
}

@media only screen and (max-width: 720px) {
  #chartDetails {
    grid-template-rows: repeat(4, auto);
    grid-template-columns: 1fr auto;
    grid-template-areas:
      "additionalOptions additionalOptions"
      "chartAssetChange chartAssetChange"
      "chartAsset chartTimeLeft"
      "chartOHLC chartOHLC";
  }

  #chartDetails .additionalOptions {
    grid-template-columns: 1fr 4.5rem;
  }
}

@media only screen and (max-width: 590px) {
  #chartDetails {
    grid-template-rows: repeat(5, auto);
    grid-template-columns: 1fr;
    grid-template-areas:
      "additionalOptions"
      "chartAssetChange"
      "chartAsset"
      "chartOHLC"
      "chartTimeLeft";
  }

  #chartDetails .chartAsset {
    justify-content: center;
    text-align: center;
  }

  #chartDetails .chartOHLC ul {
    justify-content: center;
  }

  #chartDetails .chartOHLC,
  #chartDetails .chartTimeLeft {
    text-align: center;
  }
}

@media only screen and (max-width: 450px) {
  #chartDetails .chartOHLC ul {
    flex-direction: column;
    width: 100%;
  }

  #chartDetails .chartOHLC ul li {
    margin: 0;
    justify-content: center;
  }

  #chartDetails .chartOHLC ul li:first-child p:last-child {
    margin-right: 0;
  }
}

@media only screen and (max-width: 414px) {
  #chartDetails .chartAsset {
    flex-direction: column;
  }

  #chartDetails .chartPrice i {
    top: 0.7rem;
  }

  #chartDetails .chartPrice .delta {
    top: -0.1rem;
  }
}